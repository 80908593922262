var $name="appendStylesheetLinkElement",$path="app/utilities/appendStylesheetLinkElement.js",$this={$name,$path,};/**
 * Create stylesheet link and place it on page
 * @param {string} href
 */
export default function appendStylesheetLinkElement(href) {
  const stylesheet = document.createElement('link');
  stylesheet.rel = 'stylesheet';
  stylesheet.href = href;
  document.head.insertAdjacentElement('beforeend', stylesheet);
}
