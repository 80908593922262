var $class="se2--footer-global-se--bottom-bar",$name="FooterGlobalSE/bottomBar",$path="app/components/FooterGlobalSE/partials/bottomBar/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import { cookiePrivacyLinkClassName } from 'app/components/FooterGlobalSE/config';

function openCookiePrivacy(event) {
  event.preventDefault();
  window.tC?.privacyCenter?.showPrivacyCenter?.();
}

export default shell.registerPartial($this, ({ addEventListener, mount }) => {
  mount((element) => {
    if (element.closest('footer').classList.contains('footer-apc')) return;
    const cookiePrivacyLinkElement = element
      .querySelector(`.${cookiePrivacyLinkClassName}`);
    addEventListener(cookiePrivacyLinkElement, 'click', openCookiePrivacy);
  });
});
